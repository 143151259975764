import React from 'react';
import styled from 'styled-components';
import theme from 'theme';

import { useUserQuery } from 'src/api';

const Wrapper = styled.div`
  width: 100%;
  min-height: 40px;
  padding-left: 15px;
  font-family: Duplicate Sans Web;
  font-style: medium;
  font-size: 16px;
  line-height: 40px;
  color: ${theme.colors.main};
  border-bottom: 1px solid ${theme.colors.ui_sidebar_border};
  overflow: hidden;
  overflow-wrap: break-word;
`;

const UserIcon = styled.i`
  padding-top: 1px;
  margin-right: 9px;
  width: 10px;
  height: 14px;
  font-size: 10px !important;
  line-height: 10px !important;
  vertical-align: middle;
`;

function User() {
  const { data: user, isLoading } = useUserQuery();

  return (
    <Wrapper>
      <UserIcon className="fa fa-user fa-lg" /> {isLoading ? <span>Ladataan...</span> : user?.username}
    </Wrapper>
  );
}

export { User };

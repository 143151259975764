import React from 'react';
import styled from 'styled-components';

import { Header } from './header/mu/Header';

const InnerWrapper = styled.div`
  max-width: 1170px;
  min-width: 768px;
  margin: 0 auto 0;
`;

const ContentWrapper = styled.div`
  position: relative;
`;

type Props = {
  children?: any;
};

function PageWrapper({ children }: Props) {
  return (
    <div>
      <InnerWrapper>
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
      </InnerWrapper>
    </div>
  );
}

export { PageWrapper };

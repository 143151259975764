import React from 'react';
import styled from 'styled-components';

import theme from 'theme';

type Props = {
  text: string;
};

const Message = styled.div`
  margin: 10px 0;
  padding: 10px;
  background: ${theme.colors.regular_pink};
`;

function ErrorMessage({ text }: Props) {
  return <Message>{text}</Message>;
}

export { ErrorMessage };

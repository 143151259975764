import type { RootState } from 'src/store';

export type ApplicationState = RootState;

export interface UserApiData {
  username?: string;
  accountType?: string;
  orderHistory?: Object[];
  credentials?: string[];
  accountHashId?: string;
  company?: string;
}

export interface Credentials {
  username: string;
  password: string;
}

export interface PhysicianPracticePosition {
  id: number;
  modified?: string;
  fieldId: number;
  districtId: number;
  city: string;
  organization: string;
  positions: string;
  ends?: string;
  emergencyDuty: boolean;
  emergencyDutyNotes: string;
  training: string;
  tutors: boolean;
  doctoralThesis: boolean;
  salary: string;
  description: string;
  link: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  updaterName: string;
  updaterPhone: string;
  updaterEmail: string;
}

export type SkeletonPosition = Omit<PhysicianPracticePosition, 'id'>;

export function isPhysicianPracticePosition(
  position: PhysicianPracticePosition | SkeletonPosition
): position is PhysicianPracticePosition {
  return (position as PhysicianPracticePosition).id !== undefined;
}

export interface PhysicianPracticePositions {
  positions: PhysicianPracticePosition[];
}

export interface OptionPair {
  id: number;
  text: string;
}

export interface PhysicianPracticeOptions {
  fields: OptionPair[];
  districts: OptionPair[];
}

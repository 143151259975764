import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { ChangingLogo } from './ChangingLogo';

const ViewPortWrapper = styled.div`
  position: relative;
  :after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 198px;
    width: 18px;
    height: 100%;
    pointer-events: none;
  }
  ${({ hide }: { hide: boolean }) => (hide ? 'visibility: hidden; display: none;' : '')}
`;

const TopRow = styled.div`
  background: ${theme.colors.main};
  height: 70px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
`;

type Props = {
  visible: boolean;
};

function FullHeader({ visible }: Props) {
  return (
    <ViewPortWrapper hide={!visible}>
      <TopRow>
        <ChangingLogo />
      </TopRow>
    </ViewPortWrapper>
  );
}

export { FullHeader };

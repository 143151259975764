import type { MouseEvent, TouchEvent } from 'react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import type { SingleDatePickerPhrases } from 'react-dates';
import { SingleDatePicker } from 'react-dates';

import type { Moment } from 'moment';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fi';

import theme from 'theme';

const DatePickerContainer = styled.div`
  cursor: pointer;
  display: block;
  width: 100%;
  height: 44px;
  max-width: 540px;
  padding: 0;

  .SingleDatePickerInput {
    display: none;
  }

  .SingleDatePicker_picker {
    top: 0 !important;
    left: 0 !important;
  }
`;

const DatePickerBox = styled.div`
  display: block;
  width: 100%;
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 44px;
  line-height: 42px;
  overflow: hidden;
  font-size: 16px;
  font-family: Duplicate Sans Web;
  outline: 1px solid ${theme.colors.border_black};
  position: relative;
`;

const DatePickerSymbol = styled.span`
  float: right;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 14px;
  padding-right: 2px;
`;

type Props = {
  initialTimestamp: string;
  changeTimestamp: (newTimestamp: string) => void;
};

const displayFormat = 'D.M.YYYY';

function DatePicker({ initialTimestamp, changeTimestamp }: Props) {
  const [date, setDate] = useState<Moment>(moment());
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (initialTimestamp) {
      setDate(moment(initialTimestamp));
    }
  }, [initialTimestamp]);

  const onDateChange = (newDate: Moment | null) => {
    if (!newDate) {
      return;
    }

    setDate(newDate);
    setFocused(false);

    const timestamp = newDate.clone();
    timestamp.set({
      hour: 23,
      minute: 59,
      second: 59
    });

    changeTimestamp(timestamp.utc().format());
  };

  const datePickerToggle = (event: MouseEvent<HTMLDivElement> | TouchEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setFocused(true);
  };

  return (
    <DatePickerContainer>
      <DatePickerBox onMouseDown={datePickerToggle} onTouchEnd={datePickerToggle}>
        {date.format(displayFormat)}
        <DatePickerSymbol className="fa fa-calendar-alt fa-lg" />
      </DatePickerBox>
      <SingleDatePicker
        id=""
        noBorder
        date={date}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={() => undefined}
        numberOfMonths={1}
        displayFormat={displayFormat}
        readOnly
        phrases={{ closeDatePicker: 'Sulje' } as SingleDatePickerPhrases}
        hideKeyboardShortcutsPanel
      />
    </DatePickerContainer>
  );
}

export { DatePicker };

import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0;
  padding: 0px calc(23.6318407960199% - 181.49253731343282px) 0 calc(23.6318407960199% - 181.49253731343282px);
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
`;

export { Wrapper };

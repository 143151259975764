import React from 'react';

type Props = {
  width: string;
  height: string;
  viewBox: string;
};

export function TeShort(props: Props) {
  const { width: w, height: h, viewBox: vb } = props;
  return (
    <svg width={w || '340'} height={h || '280'} viewBox={vb || '0 0 340 280'} fill="white">
      <g>
        <path d="M 220.428 223.599L 241.56 223.599L 241.56 66.6656L 194.325 278.257L 125.13 278.257L 78.3101 70.3923L 78.3101 223.599L 99.4413 223.599L 99.4413 278.257L 1.58058e-06 278.257L 1.58058e-06 223.599L 18.2309 223.599L 18.2309 58.7983L 1.58058e-06 58.7983L 1.58058e-06 -1.57956e-06L 133.417 -1.57956e-06L 169.05 175.981L 205.512 -1.57956e-06L 338.101 -1.57956e-06L 338.101 58.7983L 320.698 58.7983L 320.698 224.013L 338.929 224.013L 338.929 278.671L 220.428 278.671L 220.428 223.599Z" />
      </g>
    </svg>
  );
}

import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
import { useAppSelector, selectPreviewPosition } from 'hooks';

import type { ApplicationState, PhysicianPracticePosition, SkeletonPosition } from 'types';
import { isPhysicianPracticePosition } from 'types';
import { useNavigate } from 'react-router';
import { PageWrapper } from '../common/PageWrapper';

import { Wrapper } from './Wrapper';
import { Header } from './Header';
import { Right } from './Right';
import { SideBar } from './SideBar';

const Button = styled.button`
  outline: none;
  background: ${theme.colors.main};
  color: ${theme.colors.white};
  font-weight: normal;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  width: 154px;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 30px;
`;

function SuccessPage() {
  const navigate = useNavigate();
  const previewPosition: PhysicianPracticePosition | SkeletonPosition = useAppSelector((state: ApplicationState) =>
    selectPreviewPosition(state)
  );

  const isExistingPosition = isPhysicianPracticePosition(previewPosition);

  return (
    <PageWrapper>
      <Wrapper>
        <SideBar selected={isExistingPosition ? 'positions' : 'new'} />
        <Right>
          <Header>
            {isExistingPosition ? 'Erikoistumispaikan muokkaus onnistui!' : 'Erikoistumispaikan lisäys onnistui!'}
          </Header>
          <Button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              navigate('/lista');
            }}
          >
            Näytä ilmoitukset
          </Button>
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { SuccessPage };

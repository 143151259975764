import React from 'react';
import styled from 'styled-components';
import map from 'lodash-es/map';
import { Block } from '.';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  background: #fff;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
`;

const LoadingBlock = styled(Block)`
  display: inline-flex;
  align-self: center;
`;

type Props = {
  loading: boolean;
};

function Loading({ loading }: Props) {
  if (loading) {
    return (
      <Wrapper>
        {map([0, 1, 2, 3, 4], (index) => (
          <LoadingBlock index={index} key={index} />
        ))}
      </Wrapper>
    );
  }
  return null;
}

export { Loading };

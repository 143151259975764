import { common } from './config.common'

export const config = {
  ...common,
  environment: 'prod',
  baseUrl: 'https://www.mediuutiset.fi',
  apiRoot: 'https://erikoistumispaikat.mediuutiset.fi/api/',
  assetUrl: 'https://www.mediuutiset.fi',
  cookieDomain: '.mediuutiset.fi',
  contentRouterUrl:
    'https://s3-eu-west-1.amazonaws.com/talent-content-router-data/',
  imageServiceUrl: 'https://images.almatalent.fi',
  changePasswordUrl: 'https://tunnus.almatalent.fi/unohtunut-salasana/',
}

export const common = {
  QbrickId: 2031237,
  playStoreUrl: '',
  appStoreUrl: '',
  baseUrl: 'https://www.mediuutiset.fi',
  facebookBaseUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitterBaseUrl: 'https://twitter.com/intent/tweet?text=',
  whatsappBaseUrl: 'whatsapp://send?text=',
  defaultOrderLink: '/tilaus/',
  newsletterIdAamu: 32992,
  newsletterIdIlta: 32993,
  defaultEmail: 'toimitus@mediuutiset.fi',
  description:
    'Mediuutiset on terveydenhuollon ammattilaisten suurin uutislehti.',
  rights: {
    news: 'MuUutiset',
    magazine: 'MuNakoislehti',
  },
  disqusEnabled: true,
  siteName: 'Mediuutiset',
  siteNameSlug: 'mediuutiset',
  siteShortSlug: 'mu',
  twitterHandle: '@mediuutiset',
  geoRegion: 'FI',
  specificationVersion: '1.0',
  almaVersion: '2.3',
  language: 'fi-FI',
}

import styled from 'styled-components';

const Header = styled.div`
  text-transform: uppercase;
  padding-bottom: 30px;
  height: 38px;
  font-family: Duplicate Slab Web;
  font-size: 16px;
`;

export { Header };

import React from 'react';
import styled from 'styled-components';

import { PageWrapper } from './PageWrapper';

import { Wrapper } from '../physicianpractice/Wrapper';
import { Right } from '../physicianpractice/Right';
import { SideBar } from '../physicianpractice/SideBar';

const ErrorText = styled.h1`
  text-align: center;
`;

function ErrorPage() {
  return (
    <PageWrapper>
      <Wrapper>
        <SideBar />
        <Right>
          <ErrorText>Hakemaasi sivua ei löytynyt</ErrorText>
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { ErrorPage };

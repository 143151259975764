import styled from 'styled-components';

import theme from 'theme';

const Right = styled.section`
  margin: 0;
  width: 100%;
  width: ${(100 * 610) / 980}%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-left: 1px solid ${theme.colors.ui_sidebar_border};
  overflow: auto;
`;

export { Right };

import type { ApplicationState, PhysicianPracticePosition, SkeletonPosition } from 'types';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface InitialState extends SkeletonPosition {
  id?: number;
}

const initialState: InitialState = {
  id: 0,
  fieldId: 0,
  districtId: 0,
  city: '',
  organization: '',
  positions: '',
  ends: '',
  emergencyDuty: false,
  emergencyDutyNotes: '',
  training: '',
  tutors: false,
  doctoralThesis: false,
  salary: '',
  description: '',
  link: '',
  contactName: '',
  contactPhone: '',
  contactEmail: '',
  updaterName: '',
  updaterPhone: '',
  updaterEmail: ''
};

const previewPosition = createSlice({
  name: 'previewPosition',
  initialState,
  reducers: {
    setPreviewPosition: (state, action: PayloadAction<PhysicianPracticePosition | SkeletonPosition>) => action.payload
  }
});

export const { setPreviewPosition } = previewPosition.actions;
export const selectPreviewPosition = (state: ApplicationState) => state.previewPosition;

const { reducer } = previewPosition;
export { reducer as previewPosition };

import React from 'react';
import styled from 'styled-components';

import { PageWrapper } from '../common/PageWrapper';

import { LoginForm } from './LoginForm';

const Header = styled.div`
  text-transform: uppercase;
  padding-bottom: 30px;
  font-family: Duplicate Slab Web;
  font-size: 16px;
`;

const Wrapper = styled.div`
  padding: 30px calc(50% - 285px) 30px calc(50% - 285px);
  width: 100%;
`;

function LoginPage() {
  return (
    <PageWrapper>
      <Wrapper>
        <Header>Erikoistumispaikkailmoitukset - Kirjaudu</Header>
        <LoginForm />
      </Wrapper>
    </PageWrapper>
  );
}

export { LoginPage };

import React from 'react';
import styled from 'styled-components';

import theme from 'theme';

const YesNoSwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 90px;
  height: 32px;

  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  & > input:checked + span {
    background-color: ${theme.colors.ui_yesnoswitch_yes};
  }

  & > input:checked + span:before {
    -webkit-transform: translateX(57px);
    -ms-transform: translateX(57px);
    transform: translateX(57px);
  }

  & > input:checked + span:after {
    content: 'KYLLÄ';
    left: 37%;
  }
`;

const YesNoSwitchInput = styled.input`
  display: none;

  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const YesNoSwitchSpan = styled.span`
  position: absolute;
  outline: none;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.ui_yesnoswitch_no};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 32px;

  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 50%;
  }

  &:after {
    content: 'EI';
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 63%;
    font-size: 13px;
    font-family: Duplicate Sans Web;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`;

type Props = {
  defaultValue: boolean;
  toggle: (state: boolean) => void;
};

function YesNoSwitch({ defaultValue, toggle }: Props) {
  return (
    <YesNoSwitchLabel>
      <YesNoSwitchInput type="checkbox" defaultChecked={defaultValue} onChange={(e) => toggle(e.target.checked)} />
      <YesNoSwitchSpan />
    </YesNoSwitchLabel>
  );
}

export { YesNoSwitch };

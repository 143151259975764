import React from 'react';

import { useGetSkeletonPositionQuery } from 'src/api';
import { useNavigate } from 'react-router';
import { PageWrapper } from '../common/PageWrapper';

import { Wrapper } from './Wrapper';
import { Header } from './Header';
import { Right } from './Right';
import { SideBar } from './SideBar';
import { PositionEdit } from './PositionEdit';
import { Loading } from '../common/Loading';

function CreatePage() {
  const navigate = useNavigate();
  const { data: position, isError, isLoading } = useGetSkeletonPositionQuery();

  if (isError) {
    navigate('/virhe');
  }

  return (
    <PageWrapper>
      <Wrapper>
        <SideBar selected="new" />
        <Right>
          <Header>Uusi erikoistumispaikkailmoitus</Header>
          {isLoading ? <Loading loading={isLoading} /> : position && <PositionEdit position={position} />}
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { CreatePage };

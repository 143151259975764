import React from 'react';
import styled from 'styled-components';

import { useAppSelector, selectPreviewPosition } from 'hooks';

import type { ApplicationState } from 'types';
import { isPhysicianPracticePosition } from 'types';

import theme from 'theme';
import { useNavigate } from 'react-router';
import { useUpsertPositionMutation } from 'src/api';
import { PageWrapper } from '../common/PageWrapper';

import { Wrapper } from './Wrapper';
import { Header } from './Header';
import { Right } from './Right';
import { SideBar } from './SideBar';
import { PositionView } from './PositionView';

const BackToEditButton = styled.button`
  outline: none;
  background: ${theme.colors.white};
  color: ${theme.colors.main};
  border: 1px solid ${theme.colors.main};
  font-weight: normal;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  width: 154px;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 30px;
  float: left;
`;

const SaveButton = styled.button`
  outline: none;
  background: ${theme.colors.main};
  color: ${theme.colors.white};
  font-weight: normal;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  width: 154px;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 30px;
  float: right;
`;

function PreviewPage() {
  const navigate = useNavigate();
  const [upsertPosition] = useUpsertPositionMutation();

  const previewPosition = useAppSelector((state: ApplicationState) => selectPreviewPosition(state));

  const isExistingPosition = isPhysicianPracticePosition(previewPosition);

  return (
    <PageWrapper>
      <Wrapper>
        <SideBar selected={isExistingPosition ? 'positions' : 'new'} />
        <Right>
          <Header>Tarkista erikoistumispaikkailmoitus</Header>
          <PositionView position={previewPosition} />
          <BackToEditButton
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              navigate('/muuttaminen');
            }}
          >
            Muuta ilmoitusta
          </BackToEditButton>
          <SaveButton
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              const upsert = async () => {
                const status = await upsertPosition(previewPosition).unwrap();

                if (status.success) {
                  navigate('/onnistui');
                }
              };

              upsert();
            }}
          >
            {isExistingPosition ? 'Tallenna muutokset' : 'Lähetä'}
          </SaveButton>
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { PreviewPage };

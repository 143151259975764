import React, { useEffect, useState } from 'react';
import { selectLoginMenu, useAppDispatch, useAppSelector } from 'hooks';
import { openLoginMenu } from 'actions';
import styled from 'styled-components';
import theme from 'theme';
import type { ApplicationState } from 'types';
import { FullHeader } from './FullHeader';

const HeaderWrapper = styled.div`
  z-index: 999;
`;

function Header() {
  const [floatingNavOpen, setFloatingNavOpen] = useState<boolean>(false);
  const [yPosition, setYPosition] = useState<number>(0);

  const dispatch = useAppDispatch();
  const loginMenu = useAppSelector((state: ApplicationState) => selectLoginMenu(state));

  useEffect(() => {
    window.addEventListener('scroll', setScrollPosition);
    window.addEventListener('frosmo_toggle_menu', ownPageOpenMenu, false);
    return () => {
      window.removeEventListener('scroll', setScrollPosition);
      window.removeEventListener('frosmo_toggle_menu', ownPageOpenMenu, false);
    };
  }, []);

  useEffect(() => {
    if (loginMenu) {
      dispatch(openLoginMenu(false));
    }
  }, [loginMenu]);

  const setScrollPosition = () => {
    // For opening mobile "nudge"
    if (floatingNavOpen === false && window.innerWidth < theme.breakpoints.tablet) {
      if (window.pageYOffset > 100 && window.pageYOffset < yPosition) {
        setFloatingNavOpen(true);
      }
    }
    // For closing mobile "nudge"
    if (floatingNavOpen === true && window.innerWidth < theme.breakpoints.tablet) {
      if (window.pageYOffset > 100 && window.pageYOffset > yPosition) {
        setFloatingNavOpen(false);
      }
    }
    // For rest of the viewport
    setYPosition(window.pageYOffset);
  };

  const ownPageOpenMenu = () => {
    toggleFloatingNavOnly();
  };

  const toggleFloatingNavOnly = () => {
    if (!floatingNavOpen) {
      setFloatingNavOpen(!floatingNavOpen);
    } else {
      setFloatingNavOpen(!floatingNavOpen);
    }
  };

  // No idea why this is set, as it will set FullHeader to visible disregarding the state of the floating nav
  const isSticky = true;
  return (
    <>
      <div />
      <HeaderWrapper>
        <FullHeader visible={isSticky || floatingNavOpen} />
      </HeaderWrapper>
    </>
  );
}

export { Header };

import React from 'react';
import styled from 'styled-components';

import theme from 'theme';
import { useGetActivePositionsQuery } from 'src/api';

import { useNavigate } from 'react-router';
import type { PhysicianPracticePosition } from 'types';
import { PageWrapper } from '../common/PageWrapper';

import { Wrapper } from './Wrapper';
import { Header } from './Header';
import { Right } from './Right';
import { SideBar } from './SideBar';
import { Position } from './Position';

import { Loading } from '../common/Loading';

const Count = styled.div`
  width: 100%;
  height: 44px;
  line-height: 44px;
  padding-left: 8px;
  border-bottom: 1px solid ${theme.colors.main};
`;

function ListPage() {
  const navigate = useNavigate();
  const { data: activePositions, isError, isLoading } = useGetActivePositionsQuery();

  if (isError) {
    navigate('/virhe');
  }

  const positions = activePositions?.positions || [];

  return (
    <PageWrapper>
      <Wrapper>
        <SideBar selected="positions" />
        <Right>
          <Header>Aktiiviset ilmoitukset</Header>
          {isLoading ? (
            <Loading loading={isLoading} />
          ) : (
            <>
              <Count>
                {positions.length}
                kpl
              </Count>
              {positions.map((position: PhysicianPracticePosition) => (
                <Position key={position.id} position={position} />
              ))}
            </>
          )}
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { ListPage };

import React from 'react';
import styled from 'styled-components';

import { LogoMu } from '../../LogoMu';

const LogoWrapper = styled.div`
  padding-left: calc(23.6318407960199% - 177.49253731343282px);
`;

function ChangingLogo() {
  return (
    <LogoWrapper>
      <LogoMu size="170" />
    </LogoWrapper>
  );
}

export { ChangingLogo };

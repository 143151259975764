import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useAppDispatch } from 'hooks';

import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fi';

import { fixUrlProtocol } from 'utils';

import type { PhysicianPracticePosition, SkeletonPosition } from 'types';
import { isPhysicianPracticePosition } from 'types';

import { setPreviewPosition } from 'actions';

import theme from 'theme';

import { useNavigate } from 'react-router';
import { useOptionsQuery } from 'src/api';
import { DropDownMenu } from './DropDownMenu';
import { YesNoSwitch } from './YesNoSwitch';
import { DatePicker } from './DatePicker';

const Wrapper = styled.div`
  width: 100%;
  padding-left: 15px;
`;

const TextBox = styled.input`
  display: block;
  width: 100%;
  max-width: 540px;
  margin-bottom: 10px;
  outline: 1px solid ${theme.colors.border_black};
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 44px;
  line-height: 42px;
  font-size: 16px;
  font-family: Duplicate Sans Web;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TextBoxTip = styled.div`
  max-width: 540px;
  color: ${theme.colors.button_gray};
  font-size: 14px;
  line-height: 16px;
  margin-top: 15px;
  margin-bottom: 2px;
`;

const SubHeader = styled.div`
  max-width: 540px;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  margin-top: 30px;
  margin-bottom: 2px;
`;

const YesNoOptionContainer = styled.div`
  overflow: auto;
  max-width: 540px;
  min-height: 48px;
  margin-top: 15px;
  margin-bottom: 15px;

  & > :nth-child(1) {
    margin-top: 24px;
    margin-bottom: 8px;
    float: left;
  }

  & > :nth-child(2) {
    margin-top: 16px;
    margin-bottom: 0px;
    float: right;
  }
`;

function YesNoOption({
  children,
  defaultValue,
  toggle
}: {
  defaultValue: boolean;
  toggle: (state: boolean) => void;
  children?: React.ReactNode;
}) {
  return (
    <YesNoOptionContainer>
      <TextBoxTip>{children}</TextBoxTip>
      <YesNoSwitch defaultValue={defaultValue} toggle={toggle} />
    </YesNoOptionContainer>
  );
}

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 540px;
  overflow: auto;
`;

const CancelButton = styled.button`
  outline: none;
  background: ${theme.colors.white};
  color: ${theme.colors.main};
  border: 1px solid ${theme.colors.main};
  font-weight: normal;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  width: 154px;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 30px;
  float: left;
`;

const PreviewButton = styled.button`
  outline: none;
  background: ${theme.colors.main};
  color: ${theme.colors.white};
  font-weight: normal;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  width: 154px;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 30px;
  float: right;
`;

const defaultEnds = () => {
  const date = moment();

  date.add(1, 'months');

  date.set({
    hour: 23,
    minute: 59,
    second: 59
  });

  return date.utc().format();
};

type Props = {
  position: PhysicianPracticePosition | SkeletonPosition;
};

function PositionEdit({ position }: Props) {
  const fieldId = useRef<number>(0);
  const districtId = useRef<number>(0);
  const city = useRef<HTMLInputElement>({} as HTMLInputElement);
  const organization = useRef<HTMLInputElement>({} as HTMLInputElement);
  const positions = useRef<HTMLInputElement>({} as HTMLInputElement);
  const ends = useRef<string | undefined>(undefined);
  const emergencyDuty = useRef<boolean>(false);
  const emergencyDutyNotes = useRef<HTMLInputElement>({} as HTMLInputElement);
  const training = useRef<HTMLInputElement>({} as HTMLInputElement);
  const tutors = useRef<boolean>(false);
  const doctoralThesis = useRef<boolean>(false);
  const salary = useRef<HTMLInputElement>({} as HTMLInputElement);
  const description = useRef<HTMLInputElement>({} as HTMLInputElement);
  const link = useRef<HTMLInputElement>({} as HTMLInputElement);
  const contactName = useRef<HTMLInputElement>({} as HTMLInputElement);
  const contactPhone = useRef<HTMLInputElement>({} as HTMLInputElement);
  const contactEmail = useRef<HTMLInputElement>({} as HTMLInputElement);
  const updaterName = useRef<HTMLInputElement>({} as HTMLInputElement);
  const updaterPhone = useRef<HTMLInputElement>({} as HTMLInputElement);
  const updaterEmail = useRef<HTMLInputElement>({} as HTMLInputElement);

  const [endsFree, setEndsFree] = useState<boolean>(ends === undefined);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: physicianPracticeOptions } = useOptionsQuery();

  useEffect(() => {
    fieldId.current = position.fieldId;
    districtId.current = position.districtId;
    emergencyDuty.current = position.emergencyDuty;
    tutors.current = position.tutors;
    doctoralThesis.current = position.doctoralThesis;
    ends.current = position.ends;

    setEndsFree(ends === undefined);
  }, [position]);

  const setPreviewPos = (newPosition: PhysicianPracticePosition | SkeletonPosition) => {
    dispatch(setPreviewPosition(newPosition));
  };

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPosition: SkeletonPosition & {
      id?: number;
    } = {
      fieldId: fieldId.current,
      districtId: districtId.current,
      city: city.current.value,
      organization: organization.current.value,
      positions: positions.current.value,
      ends: ends.current,
      emergencyDuty: emergencyDuty.current,
      emergencyDutyNotes: emergencyDutyNotes.current.value,
      training: training.current.value,
      tutors: tutors.current,
      doctoralThesis: doctoralThesis.current,
      salary: salary.current.value,
      description: description.current.value,
      link: fixUrlProtocol(link.current.value),
      contactName: contactName.current.value,
      contactPhone: contactPhone.current.value,
      contactEmail: contactEmail.current.value,
      updaterName: updaterName.current.value,
      updaterPhone: updaterPhone.current.value,
      updaterEmail: updaterEmail.current.value
    };

    if (isPhysicianPracticePosition(position)) {
      newPosition.id = position.id;
    }

    setPreviewPos(newPosition);
    navigate('/esikatselu');
  };

  const fields = physicianPracticeOptions?.fields || [];
  const districts = physicianPracticeOptions?.districts || [];

  return (
    <Wrapper>
      <form onSubmit={submitForm}>
        <TextBoxTip>Erikoistumisala</TextBoxTip>
        {fields.length > 0 && (
          <DropDownMenu
            options={fields}
            key={`fieldId-${position.fieldId}`}
            defaultId={position.fieldId}
            selectCallback={(id: number) => {
              fieldId.current = id;
            }}
          />
        )}
        <TextBoxTip>Sairaanhoitopiiri</TextBoxTip>
        {districts.length > 0 && (
          <DropDownMenu
            options={districts}
            key={`districtId-${position.districtId}`}
            defaultId={position.districtId}
            selectCallback={(id: number) => {
              districtId.current = id;
            }}
          />
        )}
        <TextBoxTip>
          Jos täytät terveyskeskuksen tietoja, valitse se sairaanhoitopiiri, jonka alueella terveyskeskus sijaitsee.
        </TextBoxTip>
        <TextBoxTip>Kaupunki</TextBoxTip>
        <TextBox
          type="text"
          key={`city-${position.city}`}
          placeholder=""
          defaultValue={position.city}
          ref={(input) => {
            if (input) {
              city.current = input;
            }
          }}
        />
        <TextBoxTip>Organisaatio</TextBoxTip>
        <TextBox
          type="text"
          key={`organization-${position.organization}`}
          placeholder=""
          defaultValue={position.organization}
          ref={(input) => {
            if (input) {
              organization.current = input;
            }
          }}
        />
        <TextBoxTip>Erikoistumispaikkojen määrä</TextBoxTip>
        <TextBox
          type="text"
          key={`positions-${position.positions}`}
          placeholder=""
          defaultValue={position.positions}
          ref={(input) => {
            if (input) {
              positions.current = input;
            }
          }}
        />
        <YesNoOption
          key={`ends-free-${position.ends === undefined}`}
          defaultValue={position.ends === undefined}
          toggle={(input) => {
            if (input) {
              ends.current = undefined;
            } else {
              ends.current = position.ends || defaultEnds();
            }
            setEndsFree(ends === undefined);
          }}
        >
          Erikoistumispaikkaa voi hakea milloin vain?
        </YesNoOption>
        {!endsFree && <TextBoxTip>Hakuaika päättyy</TextBoxTip>}
        {!endsFree && (
          <DatePicker
            initialTimestamp={position.ends || defaultEnds()}
            changeTimestamp={(newTimestamp: string) => {
              ends.current = newTimestamp;
            }}
          />
        )}
        <YesNoOption
          key={`emergencyDuty-${position.emergencyDuty}`}
          defaultValue={position.emergencyDuty}
          toggle={(input) => {
            emergencyDuty.current = input;
          }}
        >
          Päivystys erikoistumisen aikana
        </YesNoOption>
        <TextBoxTip>Päivystyksen lisätiedot</TextBoxTip>
        <TextBox
          type="text"
          key={`emergencyDutyNotes-${position.emergencyDutyNotes}`}
          placeholder=""
          defaultValue={position.emergencyDutyNotes}
          ref={(input) => {
            if (input) {
              emergencyDutyNotes.current = input;
            }
          }}
        />
        <TextBoxTip>Tarjottava koulutus</TextBoxTip>
        <TextBox
          type="text"
          key={`training-${position.training}`}
          placeholder=""
          defaultValue={position.training}
          ref={(input) => {
            if (input) {
              training.current = input;
            }
          }}
        />
        <YesNoOption
          key={`tutors-${position.tutors}`}
          defaultValue={position.tutors}
          toggle={(input) => {
            tutors.current = input;
          }}
        >
          Onko määritetyt tutorit?
        </YesNoOption>
        <YesNoOption
          key={`doctoralThesis-${position.doctoralThesis}`}
          defaultValue={position.doctoralThesis}
          toggle={(input) => {
            doctoralThesis.current = input;
          }}
        >
          Onko mahdollisuus tehdä väitöskirja?
        </YesNoOption>
        <TextBoxTip>Palkka</TextBoxTip>
        <TextBox
          type="text"
          key={`salary-${position.salary}`}
          placeholder=""
          defaultValue={position.salary}
          ref={(input) => {
            if (input) {
              salary.current = input;
            }
          }}
        />
        <TextBoxTip>Muuta tietoa</TextBoxTip>
        <TextBox
          type="text"
          key={`description-${position.description}`}
          placeholder=""
          defaultValue={position.description}
          ref={(input) => {
            if (input) {
              description.current = input;
            }
          }}
        />
        <TextBoxTip>Linkki</TextBoxTip>
        <TextBox
          type="text"
          key={`link-${position.link}`}
          placeholder=""
          defaultValue={position.link}
          ref={(input) => {
            if (input) {
              link.current = input;
            }
          }}
        />
        <SubHeader>Lisätietojen antaja</SubHeader>
        <TextBoxTip>Nimi</TextBoxTip>
        <TextBox
          type="text"
          key={`contactName-${position.contactName}`}
          placeholder=""
          defaultValue={position.contactName}
          ref={(input) => {
            if (input) {
              contactName.current = input;
            }
          }}
        />
        <TextBoxTip>Puhelinnumero</TextBoxTip>
        <TextBox
          type="text"
          key={`contactPhone-${position.contactPhone}`}
          placeholder=""
          defaultValue={position.contactPhone}
          ref={(input) => {
            if (input) {
              contactPhone.current = input;
            }
          }}
        />
        <TextBoxTip>Sähköposti</TextBoxTip>
        <TextBox
          type="text"
          key={`contactEmail-${position.contactEmail}`}
          placeholder=""
          defaultValue={position.contactEmail}
          ref={(input) => {
            if (input) {
              contactEmail.current = input;
            }
          }}
        />
        <SubHeader>Tietojen päivittäjä</SubHeader>
        <TextBoxTip>Nimi</TextBoxTip>
        <TextBox
          type="text"
          key={`updaterName-${position.updaterName}`}
          placeholder=""
          defaultValue={position.updaterName}
          ref={(input) => {
            if (input) {
              updaterName.current = input;
            }
          }}
        />
        <TextBoxTip>Puhelinnumero</TextBoxTip>
        <TextBox
          type="text"
          key={`updaterPhone-${position.updaterPhone}`}
          placeholder=""
          defaultValue={position.updaterPhone}
          ref={(input) => {
            if (input) {
              updaterPhone.current = input;
            }
          }}
        />
        <TextBoxTip>Sähköposti</TextBoxTip>
        <TextBox
          type="text"
          key={`updaterEmail-${position.updaterEmail}`}
          placeholder=""
          defaultValue={position.updaterEmail}
          ref={(input) => {
            if (input) {
              updaterEmail.current = input;
            }
          }}
        />
        {isPhysicianPracticePosition(position) ? (
          <ButtonContainer>
            <CancelButton
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                navigate('/lista');
              }}
            >
              Peru
            </CancelButton>
            <PreviewButton type="submit">Esikatsele muutokset</PreviewButton>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <PreviewButton type="submit">Esikatsele ilmoitus</PreviewButton>
          </ButtonContainer>
        )}
      </form>
    </Wrapper>
  );
}

export { PositionEdit };

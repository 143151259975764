import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useGetPositionQuery } from 'src/api';
import { PageWrapper } from '../common/PageWrapper';

import { Wrapper } from './Wrapper';
import { Header } from './Header';
import { Right } from './Right';
import { SideBar } from './SideBar';
import { PositionEdit } from './PositionEdit';
import { Loading } from '../common/Loading';

function EditPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { data: selectedPosition, isLoading, isError } = useGetPositionQuery(`${params.id}`);

  if (isError) {
    navigate('/virhe');
  }

  return (
    <PageWrapper>
      <Wrapper>
        <SideBar selected="positions" />
        <Right>
          <Header>Muokkaa ilmoitusta</Header>
          {isLoading ? (
            <Loading loading={isLoading} />
          ) : (
            selectedPosition && <PositionEdit position={selectedPosition} />
          )}
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { EditPage };

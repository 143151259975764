import React from 'react';
import styled from 'styled-components';

import * as moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fi';

import type { PhysicianPracticePosition, SkeletonPosition } from 'types';

import { getOption } from 'utils';

import theme from 'theme';
import { useOptionsQuery } from 'src/api';

const Wrapper = styled.div`
  width: 100%;
  padding-left: 15px;
`;

const Title = styled.div`
  margin-bottom: 4px;
  height: 48px;
  font-family: Duplicate Slab Web;
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
`;

const Preline = styled.div`
  margin-bottom: 30px;
  height: 16px;
  font-family: Duplicate Slab Web;
  font-size: 14px;
  line-height: 16px;
  color: ${theme.colors.main};
  text-transform: uppercase;
`;

const DescriptionItem = styled.div`
  margin-bottom: 6px;
  min-height: 19px;
  font-size: 14px;
  line-height: 14px;

  div {
    line-height: 16px;
  }

  h1 {
    margin-top: 30px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
`;

function YesNo({ bool }: { bool: boolean }) {
  return <span>{bool ? 'Kyllä. ' : 'Ei. '}</span>;
}

type Props = {
  position: PhysicianPracticePosition | SkeletonPosition;
};

function PositionView({ position }: Props) {
  const { data: physicianPracticeOptions, isSuccess } = useOptionsQuery();

  const fields = physicianPracticeOptions?.fields || [];
  const districts = physicianPracticeOptions?.districts || [];

  return (
    <Wrapper>
      <Title>{isSuccess && getOption(fields, position.fieldId)}</Title>
      <Preline>
        {position.city}, {isSuccess && getOption(districts, position.districtId)}
      </Preline>
      <DescriptionItem>
        Organisaatio:
        {position.organization}
      </DescriptionItem>
      <DescriptionItem>Erikoistumispaikkojen määrä: {position.positions}</DescriptionItem>
      <DescriptionItem>
        Hakuaika päättyy:{' '}
        {position.ends ? moment(position.ends).format('D.M.YYYY [klo] HH:mm') : 'Paikkaa voi hakea milloin vain.'}
      </DescriptionItem>
      <DescriptionItem>
        Päivystys erikoistumisen aikana: <YesNo bool={position.emergencyDuty} />
        {position.emergencyDutyNotes}
      </DescriptionItem>
      <DescriptionItem>
        Määritetyt tutorit: <YesNo bool={position.tutors} />
      </DescriptionItem>
      <DescriptionItem>Tarjottava koulutus: {position.training}</DescriptionItem>
      <DescriptionItem>
        Mahdollisuus tehdä väitöskirja: <YesNo bool={position.doctoralThesis} />
      </DescriptionItem>
      <DescriptionItem>
        Palkka:
        {position.salary}
      </DescriptionItem>
      <DescriptionItem>
        <h1>Muuta tietoa</h1>
        <div>{position.description}</div>
      </DescriptionItem>
      {position.link && (
        <DescriptionItem>
          <h1>Linkki</h1>
          <a href={position.link}>{position.link}</a>
        </DescriptionItem>
      )}
      <DescriptionItem>
        <h1>Lisätietoja</h1>
        <div>{position.contactName}</div>
        <div>{position.contactPhone}</div>
        <div>{position.contactEmail}</div>
      </DescriptionItem>
      <DescriptionItem>
        <h1>Tietojen päivittäjä</h1>
        <div>{position.updaterName}</div>
        <div>{position.updaterPhone}</div>
        <div>{position.updaterEmail}</div>
      </DescriptionItem>
    </Wrapper>
  );
}

export { PositionView };

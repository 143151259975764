import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { ApplicationState } from 'types';

const initialState = false;

const loginMenu = createSlice({
  name: 'loginMenu',
  initialState,
  reducers: {
    openLoginMenu: (state, action: PayloadAction<boolean>) => action.payload
  }
});

export const { openLoginMenu } = loginMenu.actions;
export const selectLoginMenu = (state: ApplicationState) => state.loginMenu;

const { reducer } = loginMenu;
export { reducer as loginMenu };

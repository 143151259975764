import { createGlobalStyle } from 'styled-components';

const theme = {
  colors: {
    main: '#56C5D0',
    darker_main: '#009EB4',
    lighter_main: '#94D6DA',
    lightest_main: '#b7f1f4',
    white: '#ffffff',
    background: '#f8f8f8',
    blue: '#0090bb',
    text: '#282828',
    body_text: '#282828',
    button_gray: '#807f7f',
    button_green: '#1fe815',
    button_blue: '#0090bb',
    inactive_gray: '#e2e2e2',
    visited_link_gray: '#7d7d7d',
    gray: '#f2f2fb',
    lighter_gray: '#BDBDBD',
    medium_blue: '#7AB8C4',
    regular_pink: '#f8d4da',
    orange: '#ea6645',
    real_black: '#000000',
    partner_bg: '#fef4f2',
    partner_bg_dark: '#fde9e6',
    partner_bg_hover: '#fbe0da',
    border_black: '#333333',
    ui_sidebar_selected: '#f4f4f4',
    ui_sidebar_hover: '#c4c4c4',
    ui_sidebar_border: '#d4d4d4',
    ui_dropdown_selected: '#e4e4e4',
    ui_dropdown_hover: '#c4c4c4',
    ui_yesnoswitch_yes: '#56c5d0',
    ui_yesnoswitch_no: '#c4c4c4',
    ui_position_hover: '#f9f9f9'
  },
  breakpoints: {
    desktop: 1190,
    tablet: 640,
    mobile: 0
  },
  sectionWidths: {
    pageMaxWidth: '970px',
    mainContentPercentage: '60.30927835%',
    sidebarPercentage: '39.690721649%'
  },
  fonts: {
    sans: '"Duplicate Sans Web", serif; font-feature-settings: "pnum" 1, "lnum" 1',
    text: '"Duplicate Sans Web", serif; font-feature-settings: "ss02"',
    heading: '"Duplicate Slab Web", serif; font-feature-settings: "ss02"',
    subheading: '"Duplicate Slab Web", sans-serif; font-feature-settings: "pnum" 1, "lnum" 1',
    partner: 'Helvetica'
  }
};

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-BlackItalic-Web.eot');
        src: url('/static/fonts/DuplicateSlab-BlackItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-BlackItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-BlackItalic-Web.woff') format('woff');
        font-weight: 900;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-Black-Web.eot');
        src: url('/static/fonts/DuplicateSlab-Black-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-Black-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-Black-Web.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-BoldItalic-Web.eot');
        src: url('/static/fonts/DuplicateSlab-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-BoldItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-BoldItalic-Web.woff') format('woff');
        font-weight: 700;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-Bold-Web.eot');
        src: url('/static/fonts/DuplicateSlab-Bold-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-Bold-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-Bold-Web.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-MediumItalic-Web.eot');
        src: url('/static/fonts/DuplicateSlab-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-MediumItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-MediumItalic-Web.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-Medium-Web.eot');
        src: url('/static/fonts/DuplicateSlab-Medium-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-Medium-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-Medium-Web.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-RegularItalic-Web.eot');
        src: url('/static/fonts/DuplicateSlab-RegularItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-RegularItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-RegularItalic-Web.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-Regular-Web.eot');
        src: url('/static/fonts/DuplicateSlab-Regular-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-Regular-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-Regular-Web.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-LightItalic-Web.eot');
        src: url('/static/fonts/DuplicateSlab-LightItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-LightItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-LightItalic-Web.woff') format('woff');
        font-weight: 300;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-Light-Web.eot');
        src: url('/static/fonts/DuplicateSlab-Light-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-Light-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-Light-Web.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-ThinItalic-Web.eot');
        src: url('/static/fonts/DuplicateSlab-ThinItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-ThinItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-ThinItalic-Web.woff') format('woff');
        font-weight: 100;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Slab Web';
        src: url('/static/fonts/DuplicateSlab-Thin-Web.eot');
        src: url('/static/fonts/DuplicateSlab-Thin-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSlab-Thin-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSlab-Thin-Web.woff') format('woff');
        font-weight: 100;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-BlackItalic-Web.eot');
        src: url('/static/fonts/DuplicateSans-BlackItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-BlackItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-BlackItalic-Web.woff') format('woff');
        font-weight: 900;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-Black-Web.eot');
        src: url('/static/fonts/DuplicateSans-Black-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-Black-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-Black-Web.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-BoldItalic-Web.eot');
        src: url('/static/fonts/DuplicateSans-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-BoldItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-BoldItalic-Web.woff') format('woff');
        font-weight: 700;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-Bold-Web.eot');
        src: url('/static/fonts/DuplicateSans-Bold-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-Bold-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-Bold-Web.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-MediumItalic-Web.eot');
        src: url('/static/fonts/DuplicateSans-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-MediumItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-MediumItalic-Web.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-Medium-Web.eot');
        src: url('/static/fonts/DuplicateSans-Medium-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-Medium-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-Medium-Web.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-RegularItalic-Web.eot');
        src: url('/static/fonts/DuplicateSans-RegularItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-RegularItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-RegularItalic-Web.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-Regular-Web.eot');
        src: url('/static/fonts/DuplicateSans-Regular-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-Regular-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-Regular-Web.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-LightItalic-Web.eot');
        src: url('/static/fonts/DuplicateSans-LightItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-LightItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-LightItalic-Web.woff') format('woff');
        font-weight: 300;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-Light-Web.eot');
        src: url('/static/fonts/DuplicateSans-Light-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-Light-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-Light-Web.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-ThinItalic-Web.eot');
        src: url('/static/fonts/DuplicateSans-ThinItalic-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-ThinItalic-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-ThinItalic-Web.woff') format('woff');
        font-weight: 100;
        font-style: italic;
        font-stretch: normal;
    }
    @font-face {
        font-family: 'Duplicate Sans Web';
        src: url('/static/fonts/DuplicateSans-Thin-Web.eot');
        src: url('/static/fonts/DuplicateSans-Thin-Web.eot?#iefix') format('embedded-opentype'),
        url('/static/fonts/DuplicateSans-Thin-Web.woff2') format('woff2'),
        url('/static/fonts/DuplicateSans-Thin-Web.woff') format('woff');
        font-weight: 100;
        font-style: normal;
        font-stretch: normal;
    }

    @font-face {
        font-family: 'Talentumbold';
        font-weight: normal;
        font-style: normal;
        src: url(/static/fonts/talentumbold.woff) format('woff');
    }
    html {
        font-family: ${theme.fonts.sans};
        box-sizing: border-box;
    }
    body {
        background-color: ${theme.colors.white};
        margin: 0;
    }
    nav::-webkit-scrollbar,
    nav::-webkit-scrollbar-button,
    nav::-webkit-scrollbar-track,
    nav::-webkit-scrollbar-track-piece,
    nav::-webkit-scrollbar-thumb,
    nav::-webkit-scrollbar-corner,
    nav::-webkit-resizer {
        -webkit-appearance: none;
        background-color: transparent;
        width: 0;
        height: 0;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
`;

export { GlobalStyle };
export default { ...theme };

import styled from 'styled-components';
import theme from 'theme';

const SideItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 40px;
  padding-left: 15px;
  font-family: Duplicate Sans Web;
  font-style: medium;
  font-size: 16px;
  line-height: 40px;
  overflow: hidden;

  ${({ selected }: { selected?: boolean }) =>
    selected ? `background-color: ${theme.colors.ui_sidebar_selected}; color: ${theme.colors.darker_main};` : ''}

  &:hover {
    background-color: ${theme.colors.ui_sidebar_hover};
  }
`;

export { SideItem };

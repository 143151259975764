import styled, { keyframes } from 'styled-components';
import theme from 'theme';

export const TextInput = styled.input`
  height: 30px;
  padding-left: 5px;
`;

const LoadingAnimation = keyframes`
    0%   { opacity: .2; }
    20%  { opacity:  1; }
    100% { opacity: .2; }
`;

type BlockProps = {
  index: number;
};

export const Block = styled.div`
  background-color: ${theme.colors.main};
  margin: 4px;
  min-height: 10px;
  min-width: 10px;
  animation-name: ${LoadingAnimation};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-delay: ${({ index }: BlockProps) => index * 0.2}s;
`;

import React from 'react';
import { useAppSelector } from 'hooks';
import type { ApplicationState, PhysicianPracticePosition, SkeletonPosition } from 'types';
import { isPhysicianPracticePosition } from 'types';
import { PageWrapper } from '../common/PageWrapper';
import { Wrapper } from './Wrapper';
import { Header } from './Header';
import { Right } from './Right';
import { SideBar } from './SideBar';
import { PositionEdit } from './PositionEdit';

function ReEditPage() {
  const previewPosition: PhysicianPracticePosition | SkeletonPosition = useAppSelector(
    (state: ApplicationState) => state.previewPosition
  );

  const isExistingPosition = isPhysicianPracticePosition(previewPosition);

  return (
    <PageWrapper>
      <Wrapper>
        <SideBar selected={isExistingPosition ? 'positions' : 'new'} />
        <Right>
          <Header>Muuta ilmoitusta</Header>
          <PositionEdit position={previewPosition} />
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { ReEditPage };

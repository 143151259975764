import React from 'react';

import { Link } from './Link';
import { MuLogo } from '../../assets/Mu';
import { TeShort } from '../../assets/TeShort';

type Props = {
  size: string;
  short?: boolean;
};

function LogoMu({ short, size }: Props) {
  return (
    <Link href="/">
      {short ? (
        <TeShort width={size} height={size} viewBox="0 -65 340 480" />
      ) : (
        <MuLogo width="155" height="70" viewBox="80 20 400 250" />
      )}
    </Link>
  );
}

export { LogoMu };

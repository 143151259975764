import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

import * as moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fi';

import type { PhysicianPracticePosition } from 'types';
import { getOption } from 'utils';

import theme from 'theme';
import { useDeletePositionMutation, useOptionsQuery } from 'src/api';
import { Loading } from '../common/Loading';

const Wrapper = styled.div`
  cursor: pointer;
  width: 100%;
  min-height: 72px;
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${theme.colors.lighter_main};
  overflow: auto;

  &:hover {
    background-color: ${theme.colors.ui_position_hover};
  }
`;

const Header = styled.div`
  min-height: 13px;
  font-family: Duplicate Sans Web;
  font-style: medium;
  font-size: 11px;
  line-height: 13px;
  color: ${theme.colors.main};
  text-transform: uppercase;
  margin-bottom: 6px;
`;

const Title = styled.div`
  min-height: 18px;
  font-family: Duplicate Slab Web;
  font-style: medium;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 6px;
`;

const Ends = styled.div`
  min-height: 15px;
  font-family: Duplicate Sans Web;
  font-style: medium;
  font-size: 12px;
  line-height: 15px;
  color: ${theme.colors.button_gray};
`;

const Container = styled.div`
  float: left;
  margin: 0;
  padding: 0;
  max-width: 473px;
`;

const ActionBox = styled.div`
  float: right;
  margin-right: 8px;
  margin-bottom: 8px;
  width: 25px;
  height: 25px;
  border: 1px solid ${theme.colors.main};
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 6px;
  color: ${theme.colors.main};
  line-height: 0;
  text-align: center;
  background-color: ${theme.colors.white};
`;

const ActionBoxSymbol = styled.i`
  padding: 0;
  margin: 0;
  width: 11px;
  height: 13px;
  font-size: 11px !important;
  line-height: 13px !important;
`;

type Props = {
  position: PhysicianPracticePosition;
};

function Position({ position }: Props) {
  const navigate = useNavigate();

  const [deletePosition] = useDeletePositionMutation();
  const { data: physicianPracticeOptions, isLoading } = useOptionsQuery();

  const fields = physicianPracticeOptions?.fields ?? [];

  return (
    <Wrapper onClick={() => navigate(`/erikoistumispaikka/${position.id}`)}>
      <Container>
        <Header>
          {position.organization},{position.city}
        </Header>
        {isLoading ? <Loading loading={isLoading} /> : <Title>{getOption(fields, position.fieldId)}</Title>}
        <Ends>
          Hakuaika päättyy:{' '}
          {position.ends ? moment(position.ends).format('D.M.YYYY [klo] HH:mm') : 'Paikkaa voi hakea milloin vain.'}
        </Ends>
      </Container>
      <ActionBox
        onClick={(e) => {
          e.stopPropagation();

          deletePosition(`${position.id}`);
        }}
      >
        <ActionBoxSymbol className="fa fa-trash-alt fa-lg" />
      </ActionBox>
      <ActionBox
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/muokkaus/${position.id}`);
        }}
      >
        <ActionBoxSymbol className="fa fa-pencil-alt fa-lg" />
      </ActionBox>
    </Wrapper>
  );
}

export { Position };

import type { MouseEvent, TouchEvent } from 'react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import theme from 'theme';

import type { OptionPair } from 'types';

const DropDownContainer = styled.div`
  cursor: pointer;
  display: block;
  width: 100%;
  max-width: 540px;
  padding: 0;
  outline: 1px solid ${theme.colors.border_black};
`;

const DropDownBox = styled.div`
  display: block;
  width: 100%;
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 44px;
  line-height: 42px;
  overflow: hidden;
  font-size: 16px;
  font-family: Duplicate Sans Web;
  outline: 1px solid ${theme.colors.border_black};
  position: relative;
`;

const DropDownItemContainer = styled.div`
  max-height: ${({ open }: { open: boolean }) => (open ? '220px' : '0')};
  overflow: auto;
  transition: 0.2s;
`;

const DropDownItem = styled.div`
  display: block;
  width: 100%;
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 44px;
  line-height: 42px;
  overflow: hidden;
  font-size: 16px;
  font-family: Duplicate Sans Web;

  ${({ selected }: { selected: boolean }) =>
    selected ? `background-color: ${theme.colors.ui_dropdown_selected};` : ''}

  &:hover {
    background-color: ${theme.colors.ui_dropdown_hover};
  }
`;

const DropDownSymbol = styled.span`
  float: right;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 14px;
  padding-right: 2px;
`;

type Props = {
  options: OptionPair[];
  defaultId: number;
  selectCallback: (id: number) => void;
};

function DropDownMenu({ defaultId, options, selectCallback }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>(defaultId);
  const [optionsMap, setOptionsMap] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const localMap: { [key: number]: string } = {};
    options.forEach((option) => {
      localMap[option.id] = option.text;
    });
    setOptionsMap(localMap);
  }, [options]);

  const menuToggle = (event: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    setOpen((prevState) => !prevState);
  };

  const selectOption = (event: MouseEvent<HTMLDivElement>, newId: number) => {
    event.stopPropagation();
    event.preventDefault();

    selectCallback(newId);

    setOpen(false);
    setSelectedId(newId);
  };

  const optionItems = options.map((option) => (
    <DropDownItem
      key={option.id}
      selected={option.id === selectedId}
      onMouseDown={(event) => selectOption(event, option.id)}
    >
      {option.text}
    </DropDownItem>
  ));

  return (
    <DropDownContainer>
      <DropDownBox onMouseDown={menuToggle} onTouchEnd={menuToggle}>
        {optionsMap[selectedId]}
        <DropDownSymbol className={`fa fa-angle-${open ? 'up' : 'down'} fa-lg`} />
      </DropDownBox>
      <DropDownItemContainer open={open}>{optionItems}</DropDownItemContainer>
    </DropDownContainer>
  );
}

export { DropDownMenu };

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Left } from './Left';
import { SideItem } from './SideItem';
import { User } from './User';

type Props = {
  selected?: string;
};

function SideBar({ selected }: Props) {
  const navigate = useNavigate();

  return (
    <Left>
      <User />
      <SideItem onClick={() => navigate('/uusi')} selected={selected === 'new'}>
        Uusi ilmoitus
      </SideItem>
      <SideItem onClick={() => navigate('/lista')} selected={selected === 'positions'}>
        Ilmoitukset
      </SideItem>
    </Left>
  );
}

export { SideBar };

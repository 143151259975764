import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import theme, { GlobalStyle } from 'theme';
import { store } from './store';

import { ErrorPage } from './components/common/ErrorPage';
import { LoginPage } from './components/physicianpractice/LoginPage';
import { ListPage } from './components/physicianpractice/ListPage';
import { CreatePage } from './components/physicianpractice/CreatePage';
import { EditPage } from './components/physicianpractice/EditPage';
import { ReEditPage } from './components/physicianpractice/ReEditPage';
import { ViewPage } from './components/physicianpractice/ViewPage';
import { PreviewPage } from './components/physicianpractice/PreviewPage';
import { SuccessPage } from './components/physicianpractice/SuccessPage';

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div className="app">
          <Routes>
            <Route path="/kirjautuminen" element={<LoginPage />} />
            <Route path="/lista" element={<ListPage />} />
            <Route path="/uusi" element={<CreatePage />} />
            <Route path="/muokkaus/:id" element={<EditPage />} />
            <Route path="/muuttaminen" element={<ReEditPage />} />
            <Route path="/esikatselu" element={<PreviewPage />} />
            <Route path="/erikoistumispaikka/:id" element={<ViewPage />} />
            <Route path="/onnistui" element={<SuccessPage />} />
            <Route path="/virhe" element={<ErrorPage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

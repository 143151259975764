import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useGetPositionQuery } from 'src/api';
import { PageWrapper } from '../common/PageWrapper';

import { Wrapper } from './Wrapper';
import { Header } from './Header';
import { Right } from './Right';
import { SideBar } from './SideBar';
import { PositionView } from './PositionView';
import { Loading } from '../common/Loading';

function ViewPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { data: selectedPosition, isError, isLoading } = useGetPositionQuery(`${params.id}`);

  if (isError) {
    navigate('/virhe');
  }

  return (
    <PageWrapper>
      <Wrapper>
        <SideBar selected={selectedPosition?.id === undefined ? 'new' : 'positions'} />
        <Right>
          <Header>Erikoistumispaikkailmoitus</Header>
          {isLoading ? (
            <Loading loading={isLoading} />
          ) : (
            selectedPosition && <PositionView position={selectedPosition} />
          )}
        </Right>
      </Wrapper>
    </PageWrapper>
  );
}

export { ViewPage };

import React from 'react';

type Props = {
  width: string;
  height: string;
  viewBox: string;
};

export function MuLogo(props: Props) {
  const { width: w, height: h, viewBox: vb } = props;
  return (
    <svg
      fill="white"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={w || '100px'}
      height={h || '100px'}
      viewBox={vb || '0 75 10 150'}
    >
      <g>
        <path d="M70.3,162.1h5.1v-38l-11.3,51.1H47.3l-11.2-50.2v37h5.1v13.2h-24v-13.2h4.4v-39.9h-4.4v-14.2h32.2l8.6,42.5l8.8-42.5h32v14.2h-4.4v39.9h4.4v13.2H70.3V162.1z" />
        <path d="M100.1,150.5v-0.1c0-16.9,11.4-25.9,25.6-25.9c14.7,0,23.1,8.1,23.1,20.9c0,4.2-1,6.9-1.9,8.7h-27.4c0.7,6.9,5.2,8.1,13.1,8.1c5.9,0,10.3-1.2,13.5-2.5v9.6c-2.4,3.4-9.3,7-19.3,7C109.3,176.4,100.1,167.3,100.1,150.5z M119.4,146.2h12.4v-1c0-6.4-1.5-9.1-5.6-9.1S119.6,138.9,119.4,146.2z" />
        <path d="M181.6,108.7h23v53.4h3.9v13.2h-19.4c0,0-2.6-4.6-3-10.8c-2,5.2-5.8,12-16.1,12c-12.8,0-19.4-10.8-19.4-25.4v-0.1c0-17.1,8.4-26.4,20.7-26.4c8.8,0,12.8,5.9,14.2,10.7v-13.4h-3.9V108.7z M185.6,151.6v-1.8c0-8.5-2.8-12.5-7.6-12.5c-4.8,0-7.9,4.4-7.9,11.2v3.2c0,7.1,2.8,11.6,7.9,11.6C182.7,163.4,185.6,158.4,185.6,151.6z" />
        <polygon points="209.6,125.7 232.9,125.7 232.9,162.1 237.1,162.1 237.1,175.3 213.8,175.3 213.8,162.1 213.8,162.1 213.8,138.3 209.6,138.3" />
        <path d="M214.2,114.3c0-5.1,4.1-9.3,9.1-9.3s9.1,4.2,9.1,9.3c0,5.1-4.1,9.2-9.1,9.2S214.2,119.4,214.2,114.3z" />
        <path d="M397.4,114.3c0-5.1,4.1-9.3,9.1-9.3c5,0,9.1,4.2,9.1,9.3c0,5.1-4.1,9.2-9.1,9.2C401.5,123.5,397.4,119.4,397.4,114.3z" />
        <path d="M273.8,164.5V164c-2.7,7.6-7.6,12.5-16.5,12.5c-10.5,0-16.3-5.8-16.3-18.3v-19.9h-3.9v-12.7h23v30.1c0,5.4,1.8,7.9,5.8,7.9c4.4,0,6.9-3.8,6.9-12.3v-13.1h-6.2v-12.7H292v36.5h3.9v13.2h-19.5C276.4,175.3,273.8,170,273.8,164.5z" />
        <path d="M333.1,164.5V164c-2.7,7.6-7.6,12.5-16.5,12.5c-10.5,0-16.3-5.8-16.3-18.3v-19.9h-3.9v-12.7h23v30.1c0,5.4,1.8,7.9,5.8,7.9c4.4,0,6.9-3.8,6.9-12.3v-13.1H326v-12.7h25.3v36.5h3.9v13.2h-19.5C335.7,175.3,333.1,170,333.1,164.5z" />
        <path d="M359.9,160.4v-22.1h-4.5v-12.7c8.9-1.3,11.1-5.2,12.7-12.6H379v12.6h10.2v12.7H379v17c0,5.2,2,7.5,7.9,7.5c2,0,3.3-0.4,4.8-0.9v9.1c-2,2.7-6.3,5.5-14.6,5.5C368.3,176.6,359.9,172.6,359.9,160.4z" />
        <path d="M521.6,160.4v-22.1h-4.5v-12.7c8.9-1.3,11.1-5.2,12.7-12.6h10.8v12.6h10.2v12.7h-10.2v17c0,5.2,2,7.5,7.9,7.5c2,0,3.3-0.4,4.8-0.9v9.1c-2,2.7-6.3,5.5-14.6,5.5C530,176.6,521.6,172.6,521.6,160.4z" />
        <polygon points="392.7,125.7 416,125.7 416,162.1 420.3,162.1 420.3,175.3 397,175.3 397,162.1 397,138.3 392.7,138.3" />
        <path d="M449.4,135.5c-1.6-0.2-3.2-0.3-4.8-0.3c-4.7,0-6,1.9-6,3.5c0,1.9,1.1,3.1,3,3.4c3.9,0.6,12.4,0.8,17,3.3c5.8,3.1,7,7.5,7,12.7c0,12.4-8.2,18.7-22.6,18.7c-9,0-16.1-2.1-19.4-5.3v-9.3h14.6v3.1c1.4,0.1,2.9,0.2,4.2,0.2c4.8,0.1,6.8-1.3,6.8-3.7c0-2.1-1.8-2.9-3.6-3.2c-3.3-0.6-10.1-0.5-15.4-3.2c-5.1-2.8-6.6-8.1-6.6-13.2c0-13.9,10.6-17.9,21.6-17.9c8.9,0,15.6,2.3,18.5,4.9v9h-14.3V135.5z" />
        <path d="M467.2,150.5v-0.1c0-16.9,11.4-25.9,25.6-25.9c14.7,0,23.1,8.1,23.1,20.9c0,4.2-1,6.9-1.9,8.7h-27.4c0.7,6.9,5.2,8.1,13.1,8.1c5.9,0,10.3-1.2,13.5-2.5v9.6c-2.4,3.4-9.3,7-19.3,7C476.4,176.4,467.2,167.3,467.2,150.5z M486.5,146.2h12.4v-1c0-6.4-1.5-9.1-5.6-9.1C489.1,136,486.7,138.9,486.5,146.2z" />
        <path d="M351.4,125.7" />
      </g>
    </svg>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { userApi, physicianPracticeApi, postsApi } from 'src/api';
import { reducers } from './reducers';

const store = configureStore({
  reducer: {
    ...reducers,
    [userApi.reducerPath]: userApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [physicianPracticeApi.reducerPath]: physicianPracticeApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([userApi.middleware, physicianPracticeApi.middleware, postsApi.middleware])
});

setupListeners(store.dispatch);

export { store };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import map from 'lodash-es/map';

import { useNavigate } from 'react-router-dom';

import type { Credentials } from 'types';
import theme from 'theme';
import { useLoginMutation } from 'src/api';
import { ErrorMessage } from '../common/ErrorMessage';
import { TextInput } from '../common';

const LoginButton = styled.button`
  outline: none;
  background: ${theme.colors.main};
  color: ${theme.colors.white};
  font-weight: normal;
  border: none;
  padding: 10px 30px;
  width: 100%;
  height: 44px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 30px;
`;

const TextBox = styled(TextInput)`
  display: block;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid ${theme.colors.border_black};
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  height: 44px;
  font-size: 16px;
`;

const TextBoxTip = styled.div`
  color: ${theme.colors.button_gray};
  margin-bottom: 2px;
`;

function LoginForm() {
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [errors, setErrors] = useState<string[]>([]);

  const usernameField = useRef<HTMLInputElement>({} as HTMLInputElement);
  const passwordField = useRef<HTMLInputElement>({} as HTMLInputElement);

  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const credentials: Credentials = {
      username: usernameField.current.value,
      password: passwordField.current.value
    };

    const loginAsync = async () => {
      await login(credentials)
        .unwrap()
        .then(() => {
          navigate('/lista');
        })
        .catch(() => {
          setErrors(['Kirjautuminen epäonnistui']);
        });
    };

    loginAsync();
  };

  return (
    <form onSubmit={submitForm}>
      {map(errors, (error, i) => (
        <ErrorMessage text={error} key={i} />
      ))}
      <TextBoxTip>Tunnus</TextBoxTip>
      <TextBox
        type="text"
        placeholder=""
        ref={(instance: HTMLInputElement | null) => {
          if (instance) {
            usernameField.current = instance;
          }
        }}
      />
      <TextBoxTip>Salasana</TextBoxTip>
      <TextBox
        type="password"
        placeholder=""
        ref={(instance: HTMLInputElement | null) => {
          if (instance) {
            passwordField.current = instance;
          }
        }}
      />
      <LoginButton type="submit">Kirjaudu</LoginButton>
    </form>
  );
}

export { LoginForm };

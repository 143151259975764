import { config } from 'config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isPhysicianPracticePosition } from 'types';
import type {
  Credentials,
  PhysicianPracticeOptions,
  PhysicianPracticePosition,
  PhysicianPracticePositions,
  SkeletonPosition,
  UserApiData
} from 'types';

const { apiRoot } = config;

enum TagTypes {
  OPTIONS = 'Options',
  POSITION = 'Position',
  SKELETON = 'Skeleton',
  USER = 'User'
}

const fetchBaseQueryOpts = (baseUrlExtension?: string) =>
  fetchBaseQuery({
    baseUrl: baseUrlExtension ? apiRoot + baseUrlExtension : apiRoot,
    credentials: 'include'
  });

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQueryOpts(),
  endpoints: (builder) => ({
    login: builder.mutation<UserApiData, Credentials>({
      invalidatesTags: [TagTypes.USER],
      query: (body) => ({
        body,
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: 'login/'
      })
    }),
    user: builder.query<UserApiData, void>({
      providesTags: [TagTypes.USER],
      query: () => 'user/'
    })
  }),
  keepUnusedDataFor: 0,
  tagTypes: [TagTypes.USER]
});

export const physicianPracticeApi = createApi({
  reducerPath: 'optionsApi',
  baseQuery: fetchBaseQueryOpts('physicianpractice/'),
  endpoints: (builder) => ({
    options: builder.query<PhysicianPracticeOptions, void>({
      query: () => 'options',
      providesTags: [TagTypes.OPTIONS]
    })
  }),
  tagTypes: [TagTypes.OPTIONS]
});

export const postsApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: fetchBaseQueryOpts('physicianpractice/positions/'),
  endpoints: (builder) => ({
    getActivePositions: builder.query<PhysicianPracticePositions, void>({
      providesTags: (result) => {
        const resp = result
          ? [
              ...result.positions.map(({ id }) => ({ type: TagTypes.POSITION as const, id: `${id}` })),
              { type: TagTypes.POSITION as const, id: 'List' }
            ]
          : [{ type: TagTypes.POSITION as const, id: 'List' }];
        return resp;
      },
      query: () => 'active/0/100'
    }),
    deletePosition: builder.mutation<undefined, string>({
      invalidatesTags: [{ type: TagTypes.POSITION as const, id: 'List' }],
      query: (id) => ({
        method: 'DELETE',
        url: `position/${id}`
      }),
      transformResponse: () => undefined
    }),
    getPosition: builder.query<PhysicianPracticePosition, string>({
      providesTags: (_result, _error, id) => [{ type: TagTypes.POSITION as const, id: `${id}` }],
      query: (id) => ({
        url: `position/${id}`
      })
    }),
    getSkeletonPosition: builder.query<PhysicianPracticePosition, void>({
      providesTags: [TagTypes.SKELETON],
      query: () => 'skeleton',
      keepUnusedDataFor: 3600
    }),
    upsertPosition: builder.mutation<{ success: boolean }, PhysicianPracticePosition | SkeletonPosition>({
      invalidatesTags: (_result, _error, position: PhysicianPracticePosition | SkeletonPosition) => {
        const invalidations = [{ type: TagTypes.POSITION as const, id: 'List' }];
        if (isPhysicianPracticePosition(position)) {
          invalidations.push({ type: TagTypes.POSITION as const, id: `${position.id}` });
        }
        return invalidations;
      },
      query: (body) => ({
        body,
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        url: 'position'
      }),
      transformResponse: (resp: PhysicianPracticePosition, _meta, arg) => {
        const success = isPhysicianPracticePosition(arg)
          ? arg.id === resp.id // updated existing post
          : !!resp.id; // new post: skeleton position does not have an id. If the response has an id, it has been stored in the database
        return { success };
      }
    })
  }),
  tagTypes: [TagTypes.POSITION, TagTypes.SKELETON]
});

export const { useLoginMutation, useUserQuery } = userApi;
export const {
  useGetActivePositionsQuery,
  useDeletePositionMutation,
  useGetPositionQuery,
  useGetSkeletonPositionQuery,
  useUpsertPositionMutation
} = postsApi;
export const { useOptionsQuery } = physicianPracticeApi;

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';

interface Props {
  href?: string;
  className?: string;
  children: any;
  openInNewTab?: boolean;
  onClick?: () => void;
}

const MuLink = styled.a`
  text-decoration: none;
  color: currentColor;
  pointer-events: ${(p: { disabled: boolean }) => (p.disabled ? 'none' : 'auto')};
`;

const isExternalLink = (url: string) => {
  const pattern = /^https?:\/\//;
  return pattern.test(url);
};

function Link({ href, className, children, openInNewTab, onClick }: Props) {
  const navigate = useNavigate();

  const handleClick = (event: any) => {
    if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey || event.which === 2) {
      return;
    }
    event.preventDefault();
    if (onClick) {
      onClick();
    }
    navigate(event.currentTarget.pathname);
  };

  return (
    <MuLink
      className={className}
      href={href}
      onClick={href && !isExternalLink(href) ? handleClick : () => undefined}
      disabled={href === undefined}
      target={openInNewTab ? '_blank' : '_self'}
      rel="noopener"
    >
      {children}
    </MuLink>
  );
}

export { Link };

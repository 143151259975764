import type { OptionPair } from 'types';

export const getOption = (options: OptionPair[], id: number) => {
  let foundOptionText = '';

  options.forEach((option) => {
    if (option.id === id) {
      foundOptionText = option.text;
    }
  });

  return foundOptionText;
};
